<template>
  <div class="project-unit-status" :class="getStatusClass()">
    {{ status }}
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      return this.status
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase();
    }
  }
};
</script>

<style lang="scss">
.project-unit-status {
  @mixin statusClass($class, $color) {
    &.#{$class} {
      height: fit-content;
      font-weight: bold;
      background: $color;
      padding: 4px 12px;
      border-radius: 5px;
      color: white;
    }
  }

  @include statusClass(active, $color-success);
  @include statusClass(inactive, $color-warn);
  @include statusClass(sold, $color-accent2);
  @include statusClass(booked, darken($color-main, 10));
  @include statusClass(reservation, lighten($color-main, 15));
  @include statusClass(deleted, $color-danger);
  @include statusClass(confirmed, $color-info);
  @include statusClass(closed, $color-danger);
}
</style>
